@use '@angular/material' as mat;
@import './styles/base/base';
@import './styles/shared/shared';


@import './styles/account';
@import './styles/common';
@import './styles/table';
@import './styles/forms';
@import './styles/layout';
@import './styles/dashboard';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fak-corems-primary: mat.define-palette(mat.$indigo-palette);
$fak-corems-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fak-corems-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fak-corems-theme: mat.define-light-theme((
  color: (
    primary: $fak-corems-primary,
    accent: $fak-corems-accent,
    warn: $fak-corems-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fak-corems-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@font-face {
  font-family: 'Poppins-Regular';
  src: url("./assets/Poppins/Poppins-Regular.ttf") format('truetype');
  
}
h3{
  font-family: 'Poppins-Regular';
}
h1,h2,h3,h4,h5,a{
  margin:0
}
.heading {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  font-family: 'Poppins-Regular';
}
a{
  text-decoration: none;
}

// ----------------login form csss--------------
.login-container, .forgot-container , .createpassword-form{
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: white ;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) input{
    background-color: white !important;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 11px 0px 11px 5px;
    width: 97%;
    margin-top: 5px;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: rgb(255 255 255 / 42%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #202224;
  font-weight: 500;
  font-size: 14px;
}
.mdc-text-field {
  padding: 0 0px 0px 0px;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  background: white;
}
.mdc-text-field .mdc-floating-label {
  top: 0%;
  transform: translateY(0%);
  pointer-events: none;
}
 .mat-mdc-raised-button.mat-mdc-button-base {
  height: 40px;
  border-radius: 8px;
}
.mat-mdc-raised-button:not(:disabled) {
  color: var(--mdc-protected-button-label-text-color, inherit);
  font-weight: 600;
}
}

.error-message{
  background-color: #ffffff; 
  color: #ffffff; 
  padding: 10px;
  border-radius: 8px;
  box-shadow:0px 1px 4px 1px #dedddd;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-message  {
  background-color: #ffffff; 
  color: #ffffff; 
  padding: 10px;
  border-radius: 8px;
  box-shadow:0px 1px 4px 1px #dedddd;
  position: fixed;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: black !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87) !important;
}

.reset-container{
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 500px;
  padding: 25px 30px;
  // border-radius: 16px;
  p{
    color: #AEAEAE;
    font-size: 14px;
  }
   button {
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins-Regular;
    color: black;
} 
.mdc-button {
  padding: 0 6px 0 0px !important;
}
.mdc-dialog__actions {
  padding: 0px !important
}
.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #0000 !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-dialog-actions .mat-button-base+.mat-button-base, .mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
  margin-left: 40px !important;
}
h2{
  font-size: 20px;
}
}

.createpassword-form{
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 16px)) !important;
  }
  .mat-mdc-form-field-error {
    color: var(--mdc-theme-error, #2e2e2e);
    font-weight: 500;
    height: 1.25rem;
    font-size: 14px;
  }
}


.action-icon-box {
  display: flex;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 16px)) !important;
  box-shadow: 0px 3px 3px 1px #707070 !important;
}
.text-color{
  color: #2974F0 !important; 
}
.form-error-text{
  color: red !important;
}
.custom-icon-edit:hover{
  color: #2974F0;
  }
  .custom-icon-delete:hover{
      color: red;
  }
  .custom-icon-view:hover{
      color: orange;
  }
  .mat-mdc-form-field-error{
    display: flex !important;
  }