.d-flex{
    display: flex;
}
.flex-col{
    flex-direction: column;
}
.w-100{
    width: 100%;
}
.d-block {
    display: block;
}
.border-0{
    border: none;
}
.border-1 {
    border: 1px solid #d4d4d4;
}
.border-t{
border-top:1px solid #d4d4d4 ;
}
.border-b{
    border-bottom: 1px solid #d4d4d4;
}
.br-1 {
    border-radius: 5px;
}
.mat-icon-x2 {
    transform: scale(2);
}
.justify-space-between{
    justify-content: space-between;
}
.flexgap{
    flex: 1;
}
.align-items{
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}
.m-1{
    margin: 1rem;
}

.m-2{
    margin: 2rem;
}

.m-3{
    margin: 3rem;
}
.my-2{
margin-top: 1rem;

}
.p-0{
    padding: 0px !important;
}
.p-1 {
    padding: 0.5rem !important;
}

.p-2 {
    padding: 1rem;
}

.p-3 {
    padding: 2rem;
}
.pl-1{
    padding-left: 1.5rem;

}
.pb-1{
padding-bottom: 0.7rem;
}
.pt-1{
    padding-top: 0.5rem;
}
.mx-1{
   margin: 0 1rem;
}

.mx-2{
    margin: 0 2rem;
}

.mx-3{
    margin: 0 3rem;
}

.mt-2 {
    margin-top: 2rem;
}
.mt-1{
    margin-top: 1rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 2rem;
}
.ml-1{
    margin-left: 0.5rem;
}
.ml-2{
    margin-left: 1rem;
}
.ml-3{
    margin-left: 1.5rem;
}
.ml-4{
    margin-left: 2.5rem;
}
.ml-5{
    margin-left: 3rem;
}
.align-items-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.label-form {
    margin-bottom: 0.5rem;
    display: block;
}
.primary-icon-color {
    color: #2974F0;   
}

.badge {
    display: inline-block;
    padding: 0.35rem 0.5rem;
    font-size: 14px;
    line-height: 1;
    color: #000000;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius:  10px;
}

.text-bg-success {
    color: #fff ;
    background-color: #198754;
}

.text-bg-warning {
    color: #fff ;
    background-color: #ffc107;
}

.text-bg-danger {
    color: #fff ;
    background-color: #dc3545;
}
.box-shadow{
 box-shadow: 0px 0px 0px 1px #e7e7e7;   
}
.priceColor{
    color: #FF4242;
    font-size: 1.4rem;
}
.line-through{
    text-decoration-line: line-through;
}
.bold{
    font-weight: bold;
}
.font-500{
 font-weight: 500;   
}
.font-sm{
    font-size: 14px;
}
.font-large{
    font-size: 2.3rem;
}
.text-black{
    color: black;
}
.text-gray{
    color: #6a6a6a;
}
.rotate{
    transform: rotate(180deg);
}
.bg-price{
    background-color: #FF4242;
    color: #fff;
}
