.main-container {
    .table-header-container {
        margin-bottom: 1.5rem;
    }
    .table-search-container {
        display: flex;
        margin-bottom: 1rem;
        .table-search-inner {
            display: flex;
            position: relative;
            width: 20%;
            align-items: center;
            border: 1px solid #ced4da;
            border-radius: 0.5rem;
            padding: 0 0.5rem;
            .search-input {
                display: block;
                width: 100%;
                padding: 0.35rem 0.5rem;
                font-size: 1rem;
                font-weight: 400;
                border-radius: 5px;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ffffff00 !important;
            }
            :focus-visible{
                border: none !important;
                outline: 0 !important;
            }
            .search-icon  {
                margin-left: 0.5rem;
                position: absolute;
                right: 10px;
                color: #4050b5;
            }
        }
    }
    .table-main-container {
        overflow-x: scroll;
        margin-bottom: 25px;
        border: 1px solid #E6E9EB !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    }

    .view-header-container {
        margin-bottom: 1.5rem;
    }

    .view-main-container {
        margin-bottom: 25px;
        border: 1px solid #E6E9EB !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        .view-inner-container {
            .view-main-inner {
                padding: 0 0.5rem;
                .view-feild {
                    margin-bottom: 1rem;
                    .label-view {
                        font-size: 0.9rem;
                        color: #8a8a8a;
                        margin-bottom: 0.4rem;
                        display: block;
                    }
                    .view-span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}