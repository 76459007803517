.loading-screen {
    background: $loading-bg-color;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .loading-screen-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
            height: 200px;
        }
    }
}